import { useCallback, useState } from 'react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMPicker } from '@/components/RMPicker/RMPicker';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

export interface RecordingStorytellerSelectionDialogProps {
  open: boolean;
  onClose?: () => void;
  onOptionSelected: (recorderPersonId: string) => void;
  onContinue: () => void;
  options: {
    personId: string;
    name: string;
  }[];
  selectedOption: string;
}

export function RecordingStorytellerSelectionDialog({
  open,
  onContinue,
  onOptionSelected,
  onClose,
  options,
  selectedOption,
}: RecordingStorytellerSelectionDialogProps) {
  const isMobile = useIsMobileViewport();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleContinue = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await onContinue();
    } finally {
      setIsSubmitting(false);
    }
  }, [onContinue]);

  return (
    <RMDialog.Root open={open} onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title="Who is the storyteller?"
          message="Select the person that Remento should label as the storyteller of this recording. This cannot be changed after your recording."
          rightAdornment={onClose && <RMCloseButton onClick={onClose} />}
        />

        <RMDialog.Body>
          <RMPicker.Root>
            {options.map((option) => (
              <RMPicker.Item
                key={option.personId}
                label={option.name}
                value={option.personId}
                bold={false}
                selected={option.personId === selectedOption}
                onClick={() => {
                  onOptionSelected(option.personId);
                }}
              />
            ))}
          </RMPicker.Root>
        </RMDialog.Body>

        <RMDialog.Footer>
          <RMButton
            background="primary"
            fullWidth={isMobile}
            autoLoading
            disabled={isSubmitting}
            onClick={handleContinue}
          >
            Continue
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
